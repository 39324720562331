<template>
  <div>
    <!-- pic3A4404AD9BF988279F57339C9DF42359.jpg -->
    <img class="h-340 rounded" src="">
    <p>
      About 36,000 fans recently flocked to Copenhagen, Denmark for LEGO World! Folks who were lucky enough to get into the sold out event saw hundreds of fan creations including stunning Star Wars® battles, an amazing mosaic and an impressive pirate display. The event also featured a playground where you could wade ankle-deep in bricks and build anything you can imagine!
    </p>
    <p>
      The LEGO Universe team united with My LEGO Network on a display where fans built and showed off their own minifigures. Bunches of folks also picked up free LEGO Universe goodies with special VIP coupons they got right here on LEGOuniverse.com!
    </p>
    <p>
      These are just a few of those fans: Pumich, Kapper322, Meels553, Sigfus-dk, Trond-dk, Eyeexist, Emilm281, MasterOfShadowMatoran, WilliamSigurd, Matara4032, Globe400, Kongu3433, Sophie553
    </p>
  </div>
</template>
